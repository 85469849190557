import React from "react"
import { graphql } from "gatsby"
import Apps from "../../components/apps"
import Section from "../../components/layouts/section"

export default ({ data, location }) => {
  const about = data.wpgraphql.pages.edges[0].node
  return (
    <Apps>
      <Section width="sm" padding="md">
        <p>{about.title}</p>
      </Section>
    </Apps>
  )
}

export const query = graphql`
  query PageAbout {
    wpgraphql {
      pages(where: { name: "about" }) {
        edges {
          node {
            title
            featuredImage {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            blocks {
              ...AllBlocks
            }
          }
        }
      }
    }
  }
`
